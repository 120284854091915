<template>
  <b-container class="main-content-container px-4" fluid>
    <b-row class="page-header py-4" no-gutters>
      <b-col class="text-center text-sm-left mb-4 mb-sm-0" col sm="6">
        <h3 class="page-title">Vehicle prices configuration</h3>
        <span class="text-uppercase page-subtitle"></span>
      </b-col>
      <b-col class="text-right" v-if="$route.name !== 'index-vehicles-price-config'">
        <b-button variant="outline-dark" @click="$router.back()" style="cursor: pointer">
          <i class="material-icons">arrow_back_ios</i> Go back
        </b-button>
      </b-col>
    </b-row>
    <b-card body-class="p-0">
      <router-view/>
    </b-card>
  </b-container>
</template>

<script>
export default {
  name: "VehiclesPriceConfig"
}
</script>

<style scoped>

</style>
